import { Types } from '@contrail/sdk';
import { isReferencePropertyType, PropertyType, Type, TypeProperty } from '@contrail/types';
import { CollectionDataEntity, CollectionDataEntityType, CollectionDataUtil } from '@contrail/collection-data';
import { OrgConfig } from 'src/app/common/auth/auth.service';

export class PlaceholderUtil {
  public static async postProcessLoadedPlaceholders(
    placeholders: Array<CollectionDataEntity>,
    assortment = null,
    orgConfig: OrgConfig,
  ) {
    const planPlaceholderType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const typeMap = {
      'plan-placeholder': planPlaceholderType,
      'project-item': projectItemType,
      item: itemType,
    };

    return await CollectionDataUtil.buildHydratedCollectionDataEntities(
      placeholders,
      CollectionDataEntityType.PLAN_PLACEHOLDER,
      {
        typeMap,
        assortment,
        orgConfig,
      },
    );
  }

  public static async postProcessPlaceholder(
    placeholder: CollectionDataEntity,
    assortment = null,
    orgConfig: OrgConfig,
  ) {
    const planPlaceholderType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const typeMap = {
      'plan-placeholder': planPlaceholderType,
      'project-item': projectItemType,
      item: itemType,
    };

    return await CollectionDataUtil.buildHydratedCollectionDataEntity(
      placeholder,
      CollectionDataEntityType.PLAN_PLACEHOLDER,
      {
        typeMap,
        assortment,
        orgConfig,
      },
    );
  }

  public static getMappedProperties(typeMap: { [typeSlug: string]: Type }): TypeProperty[] {
    return CollectionDataUtil.getMappedProperties(typeMap, CollectionDataEntityType.PLAN_PLACEHOLDER);
  }

  /**
   * Clears item and project-item property values on a placeholder depending on levels
   * @param placeholder
   * @param itemOptionRemoval
   * @returns
   */
  public static async clearMappedPropertiesOnPlaceholder(placeholder: any, itemOptionRemoval = false): Promise<any> {
    const phType: any = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const itemType: any = await new Types().getType({ root: 'item', path: 'item' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    let itemProperties = itemType.typeProperties;
    let projectItemProperties = projectItemType.typeProperties;
    const entityChanges = {};
    if (itemOptionRemoval) {
      // If placeholder contains an item-option, look for properties that are either 'all', 'option' or 'overridable' level.
      const levels = ['all', 'option', 'overridable'];
      itemProperties = itemType.typeProperties.filter((prop) => levels.includes(prop.propertyLevel));
      projectItemProperties = projectItemType.typeProperties.filter((prop) => levels.includes(prop.propertyLevel));
      entityChanges['thumbnail'] = placeholder.itemFamily?.smallViewableDownloadUrl; // use thumbnail on itemFamily if item-option is removed.
      entityChanges['optionName'] = null; // clears out optionName
    } else {
      entityChanges['thumbnail'] = null;
    }

    phType.typeProperties.forEach((prop) => {
      let isProjectItemProperty = false;
      if (['createdOn', 'updatedOn'].includes(prop.slug)) {
        return;
      }
      let property: TypeProperty = itemProperties.find((p) => p.slug === prop.slug);
      if (!property) {
        property = projectItemProperties.find((p) => p.slug === prop.slug);
        isProjectItemProperty = property ? true : false;
        console.log(prop.slug, isProjectItemProperty);
      }
      if (!property || placeholder[prop.slug] === undefined || placeholder[prop.slug] === null) {
        return;
      }
      // When an item-option is removed, use value from item-family if the property level is either all or overridable
      if (itemOptionRemoval && ['all', 'overridable'].includes(property.propertyLevel)) {
        entityChanges[prop.slug] = isProjectItemProperty
          ? placeholder.itemFamily.projectItem[prop.slug]
          : placeholder.itemFamily[prop.slug];
        if (property.propertyType === PropertyType.ObjectReference) {
          entityChanges[prop.slug + 'Id'] = isProjectItemProperty
            ? placeholder.itemFamily.projectItem[prop.slug + 'Id']
            : placeholder.itemFamily[prop.slug + 'Id'];
        }
      } else {
        // When an item-option is removed, clear all values from the 'option' level.
        entityChanges[prop.slug] = null;
        if (property.propertyType === PropertyType.ObjectReference) {
          entityChanges[prop.slug + 'Id'] = null;
        }
      }
    });
    return entityChanges;
  }

  public static async deleteMappedPropertiesFromPlaceholder(placeholder: CollectionDataEntity) {
    const propertySlugsToSkipRemovalOf = ['id', 'specifiedId', 'itemFamilyId', 'itemOptionId', 'itemTypeId'];
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });

    const itemProperties = itemType.typeProperties;
    const projectItemProperties = projectItemType.typeProperties;
    const allProperties = [...itemProperties, ...projectItemProperties];

    for (const property of allProperties) {
      if (isReferencePropertyType(property.propertyType)) {
        const slug = property.slug + 'Id';
        if (!propertySlugsToSkipRemovalOf.includes(slug)) {
          delete placeholder[slug];
        }
      }

      if (!propertySlugsToSkipRemovalOf.includes(property.slug)) {
        delete placeholder[property.slug];
      }
    }
  }

  public static removeRestrictedPropertiesOnPlaceholder(placeholder: any, typeMap: any): any {
    const restrictedPlaceholderPropertySlugs = typeMap['plan-placeholder']?.restrictedTypePropertySlugs;
    const restrictedItemPropertySlugs = typeMap['item']?.restrictedTypePropertySlugs;
    const restrictedProjectItemPropertySlugs = typeMap['project-item']?.restrictedTypePropertySlugs;

    if (restrictedPlaceholderPropertySlugs?.length) {
      for (const propSlug of restrictedPlaceholderPropertySlugs) {
        delete placeholder[propSlug];
      }
    }

    if (restrictedItemPropertySlugs?.length) {
      for (const propSlug of restrictedItemPropertySlugs) {
        if (placeholder?.itemFamily) delete placeholder.itemFamily[propSlug];
        if (placeholder?.itemOption) delete placeholder.itemOption[propSlug];
      }
    }

    if (restrictedProjectItemPropertySlugs?.length) {
      for (const propSlug of restrictedProjectItemPropertySlugs) {
        if (placeholder?.itemFamily?.projectItem) delete placeholder.itemFamily.projectItem[propSlug];
        if (placeholder?.itemOption?.projectItem) delete placeholder.itemOption.projectItem[propSlug];
      }
    }

    return placeholder;
  }
}
